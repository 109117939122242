import React from 'react'

const Dashboard = () => {
  return (
    <div className='dashboard_ecomm_center'>
      <div className="dashborad_container_section_1">
        <div className="dashboard_section_headline_and_growth">
            <div className="dashboard_section_headline">Total Rented</div>
            <div className="dashboard_growth_graph"><img className = "growth_graph" src ="./growth_graph_1.svg" alt =" " /></div>
        </div>
        <div className="dashboard_value_proposition">X</div>
        <div className="dashboard_subheadline">Shelves</div>
      </div>
      <div className="dashborad_container_section_1">
        <div className="dashboard_section_headline_and_growth">
            <div className="dashboard_section_headline">Touch points</div>
            <div className="dashboard_growth_graph"><img className = "growth_graph" src ="./growth_graph_2.svg" alt =" " /></div>
        </div>
        <div className="dashboard_value_proposition">XX</div>
        <div className="dashboard_subheadline">Viewers</div>
      </div>
      <div className="dashborad_container_section_1">
        <div className="dashboard_section_headline_and_growth">
            <div className="dashboard_section_headline">Revenue </div>
            <div className="dashboard_growth_graph"><img className = "growth_graph" src ="./growth_graph_3.svg" alt =" " /></div>
        </div>
        <div className="dashboard_value_proposition">XX</div>
        <div className="dashboard_subheadline">Worth of inventory sold</div>
      </div>
      <div className="dashborad_container_section_1">
        <div className="dashboard_section_headline_and_growth">
            <div className="dashboard_section_headline">Inventory wastage</div>
            <div className="dashboard_growth_graph"><img className = "growth_graph" src ="./growth_graph_4.svg" alt =" " /></div>
        </div>
        <div className="dashboard_value_proposition">XX</div>
        <div className="dashboard_subheadline">Units</div>
      </div>
      

    </div>
  )
}

export default Dashboard
