import React, { useState } from 'react'
import Upcoming from './Upcomming';
import PastBooking from './PastBooking';
import Cancelled from './Cancelled';
import Awaiting_confirmation from './Awaiting_confirmation';

const My_bookings = () => {
  const [activeSection, setActiveSection] = useState('upcoming');
  const renderSection = () => {
    switch (activeSection) {
    
      case 'upcoming':
        return <Upcoming/>;
      case 'past':
        return <PastBooking />;
      case 'cancelled':
        return <Cancelled />;
        case 'awaiting_confirmation':
          return <Awaiting_confirmation />;
      default:
        return <Upcoming/>;
    }
  };
  return (
    <div className='my_bookings'>
       <div className="mybooking_headline"> <img  className = "booking_logo_ecomm" src = "./booking_list.svg" alt= "" /> My Bookings</div>
           <div className="mybooking_section">
           <div className="tab upcoming_table" onClick={() => setActiveSection('upcoming')}>Upcoming</div>
    <div className="tab cancel_table" onClick={() => setActiveSection('cancelled')}>Cancelled</div>
    <div className="tab past_table" onClick={() => setActiveSection('past')}>Past</div>
    <div className="tab past_table" onClick={() => setActiveSection('awaiting_confirmation')}>Awaiting confirmation</div>
           </div>
           <div className="data_section_my_bookings">
          {renderSection()}
        </div>
    </div>
  )
}

export default My_bookings
