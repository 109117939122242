import React from 'react'
import "./blog_page.scss" ;
import { BrowserRouter as Router, Route, Routes, useParams ,Link } from 'react-router-dom'; 
const Blog_page_5  = () => {
  let { id } = useParams();
  const handleEmailButtonClick = () => {
    const emailAddress = 'athar@thirdretail.com'; 
    const subject = 'Enquiry';
    const body = 'Hello, I have an enquiry:';

    const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // Open the browser-based email client (in this case, Gmail)
    window.open(mailtoLink, '_blank');
  };
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };
  return (
    <div className='blogs_section'>
   <Link   onClick={scrollToTop} className="info-sub-text" to="/Blogs">  <div className="blog_full_prev_button"> {'<'}  Back</div></Link>
    


      <div className="blog_writer_section_details">
        <div className="blog_writer_details">
          <div className="blog_writer_img"><img   className ="blog_writer_img" src ="\Blog_writer.jpg" alt = " " /> </div>
          <div className="blog_writer_timestamp_title">
            <div className="blog_writer_title">Athar Ali . CEO @ThirdRetail</div>
            <div className="blog_writer_timestamp">September 05, 2024 . 14 min. read</div>
          </div>
        </div>
        <div className="blog_writer_accounts">

           <div className="social_accounts_blog">  <a className="social-fb-third-retail" href="https://www.instagram.com/infatuatedmist/" target="_blank" rel="noreferrer">
                        <img className="social-icon" src="/Blog_insta.png" alt="" style={{ width : "30px", height : "30px"  }}/>
                              </a></div>
                              <div className="social_accounts_blog">  <a className="social-fb-third-retail" href="https://www.linkedin.com/in/connectwithathar?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app/" target="_blank" rel="noreferrer">
                                    <img className="social-icon" alt="" src="/blog_linkdin.png"  style={{ width : "30px", height : "30px"  }}/>
                              </a> </div>
                              <div className="social_accounts_blog"> <img className="social-icon"  onClick={handleEmailButtonClick}  alt="" src="/blog_mail.png"  style={{ width : "30px", height : "30px"  }}/></div>
                            

                              
        </div>
      </div>



    
      <div className="blog_full_headline">How Small Stores can Win Big with Smart Shelf Sharing!</div>

      <div className="blog_full_paragraph_sections">


                 <div className="blog_section_para">
      Are you a small store owner struggling with empty shelves and unsold stock? You're not alone. But there's hope! A new wave of tech solutions is helping small shops fight back against big retailers. Let's dive into how AI and smart shelf sharing are changing the game for small businesses.
         
                         </div>


                     <div className="blog_section_img">
                         <img  className="blog_1_img" src= "/blog_5.avif" alt ="AI/ML powering retail operations." />
                         <div className="credit_blog_5_img">Credit: Photo by Christopher Thomond/The Guardian   <a
    className='guide'
    href='https://www.theguardian.com/commentisfree/2023/sep/17/we-shed-crocodile-tears-over-our-high-streets-then-click-online-and-finish-them-off'
    target="_blank" // Opens link in a new tab
    rel="noopener noreferrer" // Security measure
  >
    <img className="guide_logo" src="/Guide.svg" alt="" /> 
  </a></div>
                         </div>

                         <div className="blog_section_para_sub_heading">
                         Why Small Stores Are Struggling 
                           </div>

        <div className="blog_section_para">First, let's look at the hard facts:</div>

<div className="blog_section_para">TRetailers are losing $1.75 trillion every year due to overstocks and out-of-stocks [1].
UK retail sales dropped by 1.1% in 2023, hitting small shops the hardest [2].
43% of small businesses still count their stock by hand [3].</div>

<div className="blog_section_para">These numbers show that small shops are having a tough time keeping up.</div>



<div className="blog_section_para_sub_heading">
Real Stories from the High Street
                           </div>

                           <dl style={{textDecoration :"underline"}}>Bookshops vs. Big Chains </dl>

<div className="blog_section_para">
Independent bookshops in the UK are closing fast. In 2005, there were 1,535 of them. By 2022, only 867 were left [4].
Take The Bookcase in Lowdham. After 40 years, they had to shut down in 2022. The owner, Jane Streeter, blamed high costs and online competition [5].
                           </div>

                           <dl style={{textDecoration :"underline"}}>Corner Shops vs. Supermarket Express Stores </dl>



<div className="blog_section_para">
While Tesco Express stores are growing, with 1,750 stores in the UK [6], small corner shops are struggling. In 2023, 76% of independent shops saw their profits go down [7].
Mandeep Singh, who runs a Premier store in Shropshire, told the BBC he's working 13-hour days, seven days a week, just to keep his shop open. He said, "It's very, very hard at the moment"  [8].

</div>

<div className="blog_section_para_sub_heading">
The Big Problem: Not Enough Data
                           </div>

                           <div className="blog_section_para">
                           Here's the real issue: small shops don't have the data they need to make smart decisions. Only 27% of independent retailers use any kind of smart tech to manage their stock [9].
                           Without good data, it's hard for small shops to know what to stock and when.
                           </div>

<div className="blog_section_para_sub_heading">
Appear Here and Pop-Up Shops tried addressing this
                           </div>
                           <div className="blog_section_para">
                           One solution that's helping is Appear Here. 
</div>



<dl style={{textDecoration :"underline"}} >How Appear Here Works</dl>
<li>Rent a space for your idea, a short term store rental marketplace.</li>


<dl style={{textDecoration :"underline"}}>The Limits of Pop-Ups</dl>
<div className="blog_section_para">While pop-ups are great, they still lack the ability to solve it for the elephant in the room which is overproduction and underconsumption affecting the whole value chain on top of the following:</div>

<li>Affordability</li>
<li>Accessibility</li>
<li>AI</li>






<div className="blog_section_para_sub_heading">
The Next Big Thing: AI-Powered Shelf Sharing with ThirdRetail
                           </div>


                           <div className="blog_section_para">Building on what already exists in the market, ThirdRetail is taking things to the next level. We use AI to help small shops and brands work together better.
                           </div>
                           <dl style={{textDecoration :"underline"}} >Here's how ThirdRetail is changing the game:</dl>
                     
<li>Smart Matching: Our AI finds the perfect match between brands and shops.</li>

<li>Future-Seeing Tech: We use smart algorithms to predict what will sell well.</li>

<li>Real-Time Updates: Shop owners can see how products are selling right away.</li>

<li>Easy to Use: Our system works with the tools shops already have.</li>

<li>Long-Term Benefits: We help shops improve their shelves for good, not just for a short time.</li>

<div className="blog_section_para_sub_heading">
Results for Real Shops
                           </div>

                           <div className="blog_section_para">
Shops using our system can expect:
</div>


<li>Single brand rents multiple shelves in multiple stores in multiple cities at the same time.</li>
<li>Stores get access to multiple brands and ability to remove and replace underperforming shelves. </li>
<li>You as a brand and a store get an opportunity to use AI to drive decision making, generate custom strategies to optimise your operations, sales and profitability. Basically an AI co-pilot at your disposal. 
</li>





<div className="blog_section_para_sub_heading">
Join the Retail Revolution
                           </div>

                           <div className="blog_section_para">
         
                           Small shops are facing big challenges, but there's hope. Register <Link className='Hyperlink_blogs'  to= "/AuthPage">"List Your Shelf"</Link> on ThirdRetail today and understand  retail isn't just about big vs. small. It's about being smart and working together. With the right tools, small shops can compete and win.

                           <div className="blog_section_para">Ready to make your shop smarter? Learn how <Link className='Hyperlink_blogs'  to= "/">ThirdRetail</Link> can help you sell more and waste less.
</div>
                           </div>




<div className="blog_section_para_sub_heading">
References
</div>
<ul>
<div className='reference_blog_5'>
  <p style={{ fontWeight: "300" }}>[1] IHL Group. (2023). "Retail's $1.75 Trillion Inventory Distortion Problem."</p>
  <p style={{ fontWeight: "300" }}>[2] British Retail Consortium. (2024). "BRC - KPMG Retail Sales Monitor December 2023."</p>
  <p style={{ fontWeight: "300" }}>[3] Shopify. (2023). "Small Business Inventory Management Statistics."</p>
  <p style={{ fontWeight: "300" }}>[4] The Booksellers Association. (2023). "UK Independent Bookshop Numbers."</p>
  <p style={{ fontWeight: "300" }}>[5] BBC News. (2022). "The Bookcase: Lowdham shop to close after 40 years."</p>
  <p style={{ fontWeight: "300" }}>[6] Tesco PLC. (2023). "Annual Report and Financial Statements 2023."</p>
  <p style={{ fontWeight: "300" }}>[7] Association of Convenience Stores. (2024). "The Local Shop Report 2023."</p>
  <p style={{ fontWeight: "300" }}>[8] BBC News. (2023). "Cost of living: The corner shops struggling to survive."</p>
  <p style={{ fontWeight: "300" }}>[9] Retail Week. (2023). "Data in Retail: Bridging the Gap."</p>
  <p style={{ fontWeight: "300" }}>[10] Appear Here. (2024). "About Us."</p>
  <p style={{ fontWeight: "300" }}>[11] Forbes. (2023). "How Pop-Up Shops Are Reviving Brick-And-Mortar Retail."</p>
</div>


</ul>

                           <div className="subscribe_section_newsletter">
                <div className="subscribe_head">Learn more about ThirdRetail</div>
                <div className="subscribe_sub_heading">Are you an investor?
Receive our deck and necessary details of our pre-seed round!</div>
                <div className="subscribe_email_button">
                    <div className="input_subscribe_field">
                        <input type="email" id="email" name="email" placeholder="Enter your email" required />
                    </div>
                    <div className="subscribe_button">
                        <div className="subscribe_border">
                            <div className="subscribe_text"> Subscribe</div>
                        </div>
                    </div>
                </div>
                             </div>


                             </div>

                              

     
    </div>
  )
}

export default Blog_page_5



