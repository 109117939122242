import React, { useState } from 'react';
import "./calculator.scss"
const Calculator = () => {
  const [inputA, setInputA] = useState('option1'); // Initialize with a default option
  const [inputB, setInputB] = useState('option1');
  const [inputC, setInputC] = useState(0);
  const [inputD, setInputD] = useState(0);
  const [result, setResult] = useState(null);

  const handleCalculate = () => {
    const a = parseFloat(inputA === 'option1' ? 1.2 : inputA === 'option2' ? 1 : 0.8);
    const b = parseFloat(inputB === 'option1' ? 1.3 : inputB === 'option2' ? 1.2 : 1.0);
    const c = parseFloat(inputC);
    const d = parseFloat(inputD);

    if (!isNaN(a) && !isNaN(b) && !isNaN(c) && !isNaN(d)) {
      const calculatedResult = (a * b * c * d) / 30;
      setResult(calculatedResult);
    } else {
      setResult(null);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupContent, setPopupContent] = useState('');

  // Handler to open the popup with specific content
  const handleIconClick = (content) => {
    setPopupContent(content);
    setIsPopupOpen(true);
  };

  // Handler to close the popup
  const closePopup = () => {
    setIsPopupOpen(false);
    setPopupContent('');
  };

  return (
<div className="fullcal">
  <div className="calculator_headline">
    <div className='calculatorhead' >Rent shelf per day estimate calculator  </div>
    </div>
    <div className="infocal">
    <div className='calculator'>
    
      <div className='contentchamberofcalculator'>




     
        <label>
          <div className="lacandtile">
          <div className='calculate_factors'>
           
            <div className="factor_index"> Location-Factor:</div>
            <div className="factor_info"> <img
                className='calculator_info'
                onClick={() =>
                  handleIconClick(`
                  Location Factor:
                     Premium (Front of store, counters, exits): 1.2
                      Standard (Middle aisles): 1
                      Economy (Back areas): 0.8
             
                  `)
                }
                src="/calculatoropener.svg"
                alt="Open Calculator"
              /></div>
           
            </div>
          <select
             id  = "factor_input"
            value={inputA}
            onChange={(e) => setInputA(e.target.value)}
          >
            <option value="option1">1.2</option>
            <option value="option2">1</option>
            <option value="option3">0.8</option>
          </select>
        
       
          </div>
        </label>
     




      
        <label>
        <div className="lacandtile">
      
        <div className='calculate_factors'>
           
           <div className="factor_index"> Shelf-Factor:</div>
           <div className="factor_info"> <img   onClick={() => handleIconClick('Shelf Factor: Top/Eye level: 1.3 Middle: 1.2 Bottom: 1.0')} 
   className='calculator_info' src ="/calculatoropener.svg" alt ="" /></div>
          
           </div>
          <select
          value={inputB}
            id  = "factor_input"
            onChange={(e) => setInputB(e.target.value)}
          >
            <option value="option1">1.3</option>
            <option value="option2">1.2</option>
            <option value="option3">1.0</option>
          </select>
       
        </div>
        </label>
       




  
        <label>
        <div className="lacandtile">
   
            <div className='calculate_factors'>
           
           <div className="factor_index"> Sample Base Rate  :    <span> (in Pounds £):</span></div>
           <div className="factor_info"> <img    onClick={() => handleIconClick('Sample Base Rate: £150 per linear foot per month (This is basically depend on your location of your store , so please estimate your rent accordingly)' )} className='calculator_info' src ="/calculatoropener.svg" alt ="" /></div>
          
           </div>  

          <input
            type="number"
            value={inputC}
            id  = "factor_input"
            onChange={(e) => setInputC(e.target.value)}
          />
             </div>
        </label>

        {isPopupOpen && (
        <Popup content={popupContent} onClose={closePopup} />
      )}

     

        <label>
        <div className="lacandtile">
        <div className='calculate_factors' > Linear feet Length :  </div> 
          <input
            type="number"
            value={inputD}
            id  = "factor_input"
            onChange={(e) => setInputD(e.target.value)}
          />
             </div>
        </label>


     
      

      <div className='calbutton'    onClick={handleCalculate}>Calculate</div>
      {result !== null && (
        <div className='output_field_result'>
          <div className='resultcal'>Result:(Shelf Rent(£) / Day)</div>
          <p className='rateoutput'>Per-day rate : {result} (paste this value)</p>
        </div>
      )}
   
   
    </div>
 
    </div>



    <div className="infocalsec">

   <div className="calmatrix">Shelf Rental Rate Matrix:</div> 

<div className="locationfactor" >Sample Base Rate:
£150 per linear foot per month  <div className="basebracet" >(This is basically depend on your location of your store , so please estimate your rent accordingly)</div>
</div>
 <div className="locationfactor" >Sample Calculation:
10 linear feet premium shelf x Base Rate £150 x Location Factor 1.2 x Shelf Factor 1.3 / 30 days = £78 per day
</div>

    </div>




    </div>
    </div>
  );
};

export default Calculator;

const Popup = ({ content, onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="close-button" onClick={onClose}><img className='close_cal_info' src ="/close_cal_info.svg" alt ="" /></button>
        <p>{content}</p>
      </div>
    </div>
  );
};
