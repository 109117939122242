import React, { useState } from 'react'
import "./blog_section.scss"
import { Content } from '@adobe/react-spectrum';

import blog_1 from './blog_images/blog_1.jpg' ;
import blog_2 from './blog_images/blog_2.jpg';
import blog_3 from './blog_images/blog_3.jpg';
import blog_4 from './blog_images/blog_4.jpg';
import blog_5 from './blog_images/blog_5.avif';
//import blog_6 from './blog_images/blog_6.jpg';
import { Link } from 'react-router-dom';


const Blog_section = () => {

    const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };
  const [currIndex, setCurrIndex] = useState(0);


const desc1 =" In the bustling world of ecommerce, the journey from product creation to customer doorstep involves navigating a labyrinth of logistics,...."
const desc2 = "In today’s competitive retail landscape, maximising your store’s profitability is essential. Here, we’ll explore strategies to increase your... "
const desc3 = " In today's rapidly evolving retail landscape, the distinction between online and offline shopping experiences is becoming...."
const desc4 = "The retail industry is rapidly evolving with the integration of machine learning (ML) to enhance customer experiences and drive sales.... "
const desc5 ="Are you a small store owner struggling with empty shelves and unsold stock? You're not alone....."
    const slides =[
    
        {id :2 , content : desc2 , heading : "Maximising In-Store Profit Per Square Foot" , img : blog_2 , tag : "" , link : "/Blogs/2" },
        {id :4 , content : desc4 , heading : "Harnessing Data for the Future of Retail" , img : blog_4 , tag : "" , link :"/Blogs/4"},
        {id :3 , content : desc3 , heading : "The Future of Retail" , img : blog_3 , tag : "" , link :"/Blogs/3"},
        {id :1 , content : desc1 , heading : "eCommerce product distribution" , img : blog_1 , tag : "" , link :"/Blogs/1"},
        {id :5 , content : desc5 , heading : "How Small Stores can Win Big with Smart Shelf Sharing!" ,img : blog_5 , tag : "" , link :"/Blogs/5"} ,
   
    ]
    const nextSlide = () => {
      setCurrIndex((currIndex + 1) % slides.length);
  };

  const prevSlide = () => {
      setCurrIndex((currIndex - 1 + slides.length) % slides.length);
  };

  const getVisibleSlides = () => {
      const sortedSlides = slides.sort((a, b) => b.id - a.id);
      const visibleSlides = [];

      for (let i = 0; i < 4; i++) {
          const index = (currIndex + i) % slides.length;
          visibleSlides.push(sortedSlides[index]);
      }
      return visibleSlides;
  };

  const visibleSlide = getVisibleSlides();


 


  return (
    <div className='blog_section'>
        <div className="blog_container_headline">Our newest <span>post</span></div>
        <div className="desc_what_are_about">Tip, tricks and insights into the world of online and offline retail synergies</div>
        <Link onClick={scrollToTop} className= "Link" to="/Blogs"><div className="gotoblog_page">Visit blog</div>
        </Link>
<div className="blog_slider_container">
  
         <div className="blog_slider_section">
          
         {visibleSlide
  .sort((a, b) => b.id - a.id) // Replace `id` with the field you want to sort by
  .map((slide) => (
    <div className="cards_blog" key={slide.id}>
      <div className="card_blog_container">
        <div className="card_blog_img">
          <img className="blog_card_img" src={slide.img} alt="Blog" />
        </div>
        <div className="card_blog_tag">{slide.tag}</div>

        <div className="card_content_headline">
          <div className="card_headlin_blog">{slide.heading}</div>
          <div className="card_blog_desc">{slide.content}</div>
          <Link onClick={scrollToTop} className="Link" to={slide.link}>
            <div className="blog_read_article_link_img">
              <div className="blog_read_article_link">Read Article</div>
              <img className="upleftarrow" src="/upleft.svg" alt="" />
            </div>
          </Link>
        </div>
      </div>
    </div>
  ))}

          </div>

</div>
   <div className="controller_blogs">
   <div className="next_button_blog" onClick={nextSlide}>
  <img src="/ArrowLeft.svg" alt="Next" />
</div>
<div className="prev_button_blog" onClick={prevSlide}>
  <img src="/ArrowRight.svg" alt="Prev" />
</div>

</div>
    </div>
  )
}

export default Blog_section
