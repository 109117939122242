import React from 'react'
import { Link, useLocation } from 'react-router-dom';
import DatePickParent from './Calender/dateRangeParent';
import { useState ,useEffect } from 'react';
import "./slphead.scss"

const Slphead = () => {

    const page_location = useLocation();
  
        // Check if the current URL pathname is "/Browseshops"
        const isBrowseShopsPage = page_location.pathname === "/Browseshops";
  
    const [authenticated, setAuthenticated] = useState(false);
    const [token, setToken] = useState('');
    const handleLogout = () => {
      localStorage.clear();
      sessionStorage.clear() ;
      setAuthenticated(false);
      setToken('');
      window.location.href = '/' ;   //  Navigate to the home page
      };
  
       const location = useLocation();
       const isLoginPage = location.pathname === '/Login';
  
       const ecomtoken = sessionStorage.getItem('ecomUser_id')  ;
  
     let first_name = localStorage.getItem('user_name1');
  let last_name = localStorage.getItem('user_name2');
  
  // Check if first_name and last_name are not present in localStorage
  if (!first_name || !last_name) {
      // If not found in localStorage, get them from sessionStorage
      first_name = sessionStorage.getItem('user_name1');
      last_name = sessionStorage.getItem('user_name2');
  }
  
  
     
  
      
       if (!localStorage.getItem('authToken')) {
        // 'authToken' is not present, execute additional cleanup
        localStorage.removeItem('user_email');
        localStorage.removeItem('storeId')
        localStorage.removeItem('user_name1');
        localStorage.removeItem('user_name2');
        }
  
  
       
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const toggleDropdown = () => {
      setDropdownVisible(!dropdownVisible);
    };
     
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  
        const handleResize = () => {
              setScreenWidth(window.innerWidth);
            };
          
        useEffect(() => {
              window.addEventListener('resize', handleResize);
              return () => {
                    window.removeEventListener('resize', handleResize);
              };
        }, [])
  
       const role = localStorage.getItem('role');
    // console.log(role)
    let linkPath;
    if (role === 'ADMIN') {
      linkPath = '/Listyourshelf';
    } else if (role === 'ECOMM') {
      linkPath = '/Ecomm_dashborad';
    } 
     
         
  
    return (
      <header>
  
              <Link className ="logo nav-item" to ="/">
                  {
                    screenWidth > 495 ? <img className="" alt="" src="https://fixedimages.s3.amazonaws.com/Logo.jpg" width="142.84px"  height="28px"/> : <img className="logo-icon" alt="" src="./navbar/mobile-logo-light.svg" width="40px"/> 
                  }
              </Link>
  
                  {
                    screenWidth > 495 ? (
                      // big screen + No Logged IN
                      !first_name && !ecomtoken ?
                        <>
                            <div className="nav-item centre" style={{marginBottom : "-20px"}} >
       < DatePickParent />
      </div>
    
  
  
  
                          <div className="nav-item right">
                            <Link className ="nav-sub-item btn btn-light" to = "/AuthPage1">Login </Link> 
                            <Link className ="nav-sub-item btn btn-dark" to = "/Login">Sign Up </Link> 
                          </div>
                        </> 
                      :
                      // big screen + Logged IN
                      <>
                          <div className="nav-item centre">
                          < DatePickParent />
                          </div> 
  
                          <div className="nav-item right logged-in-right">
                            <div className="user-profile-menu">
                                <div className="logged-in-profile sub-grid-item" onClick={toggleDropdown}>
                                  <div className="logged-in-user-name">
                                    <div className="user-profile-first-name">
                                   
                                      {first_name}
                                    </div>
                                  </div>
                                  <img className='user-icon' src="/user_icon_img.svg" alt="" />
                                </div>
                                  
                                    {dropdownVisible && (
                                          <div className="user-menu-dropdown">
                                            <div className="user-menu-items">
                                            <div className="user-menu-item">
      
      <img src = "./user_icon_img.svg" alt= "" height= "20px" width = "20px" style={{marginTop : "5px",marginLeft : "5px"}}/>
    <div className="logintext"> 
      {first_name}
    </div>
</div>
        
                                                <div className="user-menu-item">
                                                    <img src = "/booking.png" alt= ""  height= "20px" width = "20px"/>
                                                   <Link className="Link" to={linkPath}><div className="logintext">Dashboard</div> </Link>
                                                   
                                                   
                                              </div>
        
                                                <div className="logout-user"  onClick={handleLogout}>
                                                    <div className="user-menu-item">
        
                                                            <img  src = "/logout.png" alt= "" height= "20px" width = "20px"/> 
                                                        <div className="logintext" >Log out  </div>
                                                    </div>
                                                </div>
    
                                            </div>
                                          </div>
                                    )}
                            </div>
                          </div>
                      </>
                    ) 
                    
                    : (
                      // small screen + No Logged IN - hamburger menu
                      !first_name && !ecomtoken ?
                      <div className="mobile-guest-dropdown">
                        <div className="ham-dropdown" onClick={toggleDropdown}>
                          <img className='ham-icon' src="./navbar/ham.svg" alt="" />
                        </div>
  
                          {dropdownVisible && (
                            <div className="guest-menu-dropdown">
                              <div className="guest-menu-items">
                                  <div className="guest-nav-item">
                                    <Link className ="guest-nav-sub-item" to = "/AuthPage1">Login </Link> 
                                    <Link className ="guest-nav-sub-item" to = "Login">Sign Up </Link> 
                                 
                                  </div> 
                              </div> 
                            </div> 
                          )}
                        </div> 
                      :
                      // small screen + Logged IN - user menu
                      <div className="justify-right">
  
                        <div className="user-profile-menu">
                          <div className="logged-in-profile" onClick={toggleDropdown}>
                            <div className="logged-in-user-name">
                              <div className="user-profile-first-name">
                                {first_name}
                              </div>
                            </div>
  
                              <img className='user-icon' src="/user_icon_img.svg" alt="" />
                          </div>
  
                            {dropdownVisible && (
                                  <div className="user-menu-dropdown">
                                    <div className="user-menu-items">
  
                                    
                                    <div className="user-menu-item">
  
                                          <img src = "./user_icon_img.svg" alt= "" height= "20px" width = "20px" style={{marginTop : "5px",marginLeft : "5px"}}/>
                                        <div className="logintext"> 
                                          {first_name}
                                        </div>
                                    </div>
  
                                    <div className="user-menu-item">
                                          <img src = "/booking.png" alt= ""  height= "20px" width = "20px"/>
                                          <Link className="Link" to={linkPath}><div className="logintext">Dashboard</div> </Link>
                                    
                                    </div>
  
                                    {
                                      screenWidth > 495 ? null : (
                                        <>
                                          <div className="user-menu-item justify-center">
                                          < DatePickParent />
                                          </div>
                                     
                                        </>
                                      )
                                    }
  
                                    <div className="logout-user"  onClick={handleLogout}>
                                        <div className="user-menu-item">
  
                                                <img  src = "/logout.png" alt= "" height= "20px" width = "20px"/> 
                                            <div className="logintext" >Log out  </div>
                                        </div>
                                    </div>
  
                                    </div>
                                  </div>
                            )}
  
                        </div>
                      </div>
                    )
                  }
    
  
      </header>
    )
  }
  

export  default Slphead ;





  
   