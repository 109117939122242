import React, { useEffect, useState } from 'react';
import './ecomm_dash.scss';
import Cancelled from './Cancelled';
import SuperAnalytics from './SuperAnalytics';
import HelpCenter from './HelpCenter';
import { Link } from 'react-router-dom';
import Upcoming from './Upcomming';
import DashboardEcomm from './My_bookings';
import PastBooking from './PastBooking';
import My_bookings from './My_bookings';
import Dashboard from './Dashboard';
import Profile from './profile/Profile';
import Invite from './Invite';
import Connecting_store from './Connecting_store';

const EcommDash = () => {

  const [activeSection, setActiveSection] = useState('dashboard');
       

 // support email
 const receiverEmail = 'info@thirdretail.com'; 
 const handleEmailButtonClick = () => {
   const emailAddress = 'info@thirdretail.com'; 
   const subject = 'Share feedback';
   const body = "Dear ThirdRetail Team, I am writing to provide some feedback on my recent experience with the ThirdRetail website. " ;

   const mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailAddress}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

   // Open the browser-based email client (in this case, Gmail)
   window.open(mailtoLink, '_blank');
 }

  const renderSection = () => {
    switch (activeSection) {
      case 'dashboard':
        return <Dashboard/>;
      case 'upcoming':
        return <Upcoming/>;
      case 'past':
        return <PastBooking />;
      case 'cancelled':
        return <Cancelled />;
      case 'superAnalytics':
        return <SuperAnalytics />;
        case 'connectStore':
          return <Connecting_store />;
        case 'my_bookings':
          return <My_bookings />;
      case 'helpCenter':
        return <HelpCenter />;
        case 'profile':
        return <Profile />;
        case 'invite':
          return <Invite />;
      default:
      return <Dashboard/>;
    }
  };

//sidebar
const [isMobile, setIsMobile] = useState(false);  // Track if the view is mobile-sized
const [isSidebarOpen, setIsSidebarOpen] = useState(false);      // Track if the sidebar is open on mobile

// Detect if screen size is mobile or not
const handleResize = () => {
  if (window.innerWidth <= 768) {
    setIsMobile(true);
    setIsSidebarOpen(false); // Close sidebar by default on mobile
  } else {
    setIsMobile(false);
    setIsSidebarOpen(true); // Sidebar always visible on larger screens
  }
};

// Listen for window resize events
useEffect(() => {
  window.addEventListener("resize", handleResize);
  handleResize(); // Call once to set the initial state
  return () => window.removeEventListener("resize", handleResize);
}, []);

// Function to toggle sidebar on mobile view
const toggleSidebar = () => {
  setIsSidebarOpen(!isSidebarOpen);
};
  return (
    <div className='Dashboard_ecomm'>
     
    
     
     <div className={`left_ecomm ${isMobile && !isSidebarOpen ? "closed" : ""}`}>
                        
                         

      <div className="left_ecomm_upper">
                              <Link to="/">
                            <img src="/listyourshelf_logo.svg" alt="listing dashboard logo" />
                           </Link>
                           <div className="store_name_logo"> 
                               <div className="store_name_index">  {sessionStorage.getItem('user_name1')}</div>
                               <div className="store_logo">  <img className ="logo_sidebar" alt="store_owner_logo" src="user-account.svg" /></div>
                        </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('dashboard')}>
          <img className='left_ecomm_option_icon' src="./Dashboard.svg" alt="" /> Dashboard
        </div>

        <div className="left_ecomm_options" onClick={() => setActiveSection('my_bookings')}>
          <img className='left_ecomm_option_icon' src="./my_booking.svg" alt="" /> My Bookings
        </div>
      
        <Link to="/Browseshops" style={{ textDecoration: 'none', color: 'black' }}>
          <div className="left_ecomm_options">
            <img className='left_ecomm_option_icon' src="./marketplace.svg" alt="" /> Marketplace
          </div>
        </Link>
        <div className="left_ecomm_options" onClick={() => setActiveSection('connectStore')}>
          <img className='left_ecomm_option_icon' src="./connect_store.svg" alt="" /> Connect Store
        </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('superAnalytics')}>
          <img className='left_ecomm_option_icon' src="./analytics.svg" alt="" /> Super Analytics
        </div>
        <div className="left_ecomm_options"onClick={handleEmailButtonClick}>
          <img className='left_ecomm_option_icon' src="./user_help.svg" alt="" /> Help Center
        </div>
        </div>
        <div className="left_ecomm_bottom">
        <div className="left_ecomm_options" onClick={() => setActiveSection('profile')}>
          <img  className='left_ecomm_option_icon' src="./ecomm_setting.svg" alt="" /> Edit Profile
        </div>
        <div className="sharefeedback_listing" onClick={handleEmailButtonClick} >
                                <div className="store_dashboard_logo"><img className ="logo_sidebar" src="sharefeedbackicon.svg" alt ="" /></div>
                                <div className="share_feedback_on_listing">Share Feedback</div>
                            </div>
        <div className="left_ecomm_options" onClick={() => setActiveSection('invite')}>
          <img  className='left_ecomm_option_icon'src="./invite_people.svg" alt="" /> Invite People
        </div>
     
      </div>
      </div>  


      <div className="right_ecomm">
     
<div className="mobile_size_logo">
 <Link to ="/" ><div className="logo_dashboard"><img className="logo-icon" alt="" src="/navbar/mobile-logo-light.svg" width="40px"/></div></Link>
<div className="hum_menu_ecomm"> {isMobile && (
        <img
          src={isSidebarOpen ? "/close_menu.svg" : "/open_menu.svg"}  // Use open and close images
          alt={isSidebarOpen ? "Close menu" : "Open menu"}
          className="toggle-icon_ecomm_dashboard"
          onClick={toggleSidebar}
        />
      )}
</div>


</div>

     

        <div className="data_section_dashboard">
          {renderSection()}
        </div>
      </div>

    </div>
  );
}

export default EcommDash;
