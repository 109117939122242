import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const Getting_started_store = () => {
    
  const registerStoreValue = localStorage.getItem('register_store');

    console.log(registerStoreValue)
  // Check if register_store has a non-null value
  const isRegisterStorePresent = registerStoreValue !== "";
  const [username, setUsername] = useState('');

  // Function to capitalize the first letter
  const capitalizeFirstLetter = (string) => {
    if (!string) return ''; // Return empty if the string is empty
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    // Retrieve the username from local storage
    const storedUsername = localStorage.getItem('user_name1');
    
    // If a username is found, capitalize the first letter
    if (storedUsername) {
      setUsername(capitalizeFirstLetter(storedUsername));
    }
  }, []);
  const [acceptContract, setAcceptContract] = useState(false);

  useEffect(() => {
    const contractAccepted = sessionStorage.getItem('acceptContract') === 'true';
    setAcceptContract(contractAccepted);
  }, []);

//console.log("sdrad" ,sessionStorage.getItem('acceptContract')) ; 

  return (
    <div className='getting_start_registration'>

        <div className="form_containers">
                <div className="form_container_headline">
                    <div className="form_headline_main">Let’s get you started, {username}</div>
                    <div className="form_container_subheadline">
  Check the {' '}
  <a
    className='guide'
    href='https://scribehow.com/shared/List_Your_Shelf_on_Third_Retail__ZNNOGyuvRsyEYtTINoUFZQ'
    target="_blank" // Opens link in a new tab
    rel="noopener noreferrer" // Security measure
  >
     guide 
    <img className="guide_logo" src="./Guide.svg" alt="" /> 
  </a>
  {' '}to know how to list your shelf on ThirdRetail marketplace
</div>

                </div>
            <div className="forms_sections">
                <div className="form_section_topbar">
                   <div className="form_section_headline">Retail Setup</div>
                   <div className="No_of_filled_forms">1/5 steps</div>
                </div>


                <div className="main_form_board">


                    <div className="account_create_form">

                        <div className="forms_category">     
                        <div className="logo_form"><img src="createacoountlistyourself.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Create your account</div>
                            <div className="form_subheadline">Create your account</div>
                        </div>
                        </div>
                        
                        <div className="form_status_filled_icon"><img src ="./filled_icon.svg" alt  ="filled"/></div>
                      
                    </div>


                    <div className="store_form">  
                    <div className="forms_category"> 
                          <div className="logo_form"><img src="storerentyourself.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Add your Store Details</div>
                            <div className="form_subheadline">Quickly add the relevant store details</div>
                        </div>
                        </div>
                        {isRegisterStorePresent ? (
                               <div className="form_status_filled_icon"><img src ="./filled_icon.svg" alt  ="filled"/></div>
                               ) : (
                        <Link  className ="Link" to = "Form1"> <div className="form_status">Add Details</div></Link>
                    )}
                    </div>
                         




                    <div className="shelf_form">  
                    <div className="forms_category"> 
                          <div className="logo_form"> <img src="shelf_add.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Add shelves in your store </div>
                            <div className="form_subheadline">Add the details of the shelves you want to rent on the platform</div>
                        </div>
                        </div>
                        <Link  className ="Link" to = "Form2"><div className="form_status">Add Details</div></Link>
                        
                        </div>


                    <div className="bank_form">  
                    <div className="forms_category">   <div className="logo_form"><img src="contract_file.svg" alt =""/></div>
                        <div className="form_about">
                            <div className="form_headline">Terms and condition</div>
                            <div className="form_subheadline">Accept the terms and condition</div>
                        </div>
                        </div>
                        {acceptContract ? ( <img src ="./filled_icon.svg" alt  ="filled"/>):(  <Link className='Link' to = "Contract Details" > <div className="form_status" >View Terms</div>

</Link> ) } 
                  
                   
                    </div>
                </div>


                </div>    
           
        </div>
   
    </div>
  )
}

export default Getting_started_store

