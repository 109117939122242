import React, { useEffect, useState } from 'react';
import "./contract.scss";
import { color, fontWeight } from '@mui/system';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
const ContractDetails = () => {
  const today = new Date();
   const [acceptcontract , setAcceptcontract] = useState(false) ;
  

  const day = today.getDate();
  const monthIndex = today.getMonth(); // Get month index (0-11)
  const year = today.getFullYear();

  // Array of month names
  const monthNames = [
    "January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  // Get the month name
  const monthName = monthNames[monthIndex];
  const userName1 = localStorage.getItem('user_name1');
const userName2 = localStorage.getItem('user_name2');

// Capitalize the first letter of userName1
const formattedUserName1 = userName1.charAt(0).toUpperCase() + userName1.slice(1);
// Concatenate the two user names
const Customer_name = `${formattedUserName1} ${userName2}`;

const navigate = useNavigate();
useEffect(() => {
  const storedState = sessionStorage.getItem('acceptContract');
  if (storedState) {
    setAcceptcontract(JSON.parse(storedState));
  }
}, []);

// Update session storage when state changes
useEffect(() => {
  sessionStorage.setItem('acceptContract', JSON.stringify(acceptcontract));
}, [acceptcontract]);

const handleAccept = () => {
  // Show toast notification
  toast.success('Contract Accepted!', {
    position: toast.POSITION.TOP_RIGHT,
    autoClose: 3000,   // Close after 3 seconds
   
   
  });
  setAcceptcontract(true) ;
  setTimeout(() => {
    navigate('/Listyourshelf'); // Redirect to home page
  }, 3000); // 3 seconds delay

};
  return (
    <div className='contract_details_container'>
      <div className="contract-details">
        <img className='third_retail_contract_logo' src="/contract_logo.svg" alt="Contract Logo" />
        
        <h2>Shelf Listing Agreement</h2>
        <p>This Shelf Listing Agreement (“Agreement”) is made effective the {day} day of {monthName},  {year} between:</p>

        <p>
          <strong>ThirdRetail Limited</strong>, a company incorporated in England and Wales, having its registered office
          address at 128 City Road, London, United Kingdom, EC1V 2NX (hereinafter referred to as the “Marketplace”, which
          expression shall mean and include its successors and permitted assigns)
        </p>

        <p>AND</p>

        <p>
          <strong>{Customer_name}</strong>, a registered shop in the UK, having its address at <strong>[Office Address] </strong>
          (hereinafter referred to as the “Retailer”, which expression shall mean and include its successors and permitted
          assigns)
        </p>

        <h3>RECITALS</h3>
        <p>
          WHEREAS the Marketplace owns and operates an online platform enabling e-commerce businesses to list and rent
          shelf space available at brick-and-mortar retail stores;
        </p>
        <p>
          WHEREAS the Retailer owns and operates the [Name of Store] retail store at [Retail Store Address] (the
          “Premises”) and wishes to list available shelf space on the Marketplace’s platform;
        </p>

        <h3>AGREEMENT</h3>
        <h4>1. Shelf Space</h4>
        <p>1.1 The Retailer shall make available shelf space of mentioned dimensions depicted in Schedule A <span style={{ fontWeight: 500 }}>(the “Shelf Space”)</span>
        for rental at the Premises via the Marketplace’s platform.</p>
        <p>1.2 The Shelf Space shall be [Z sq. ft.] in size, be capable of handling up to [W kg] product weight, and contain the following electrical fixtures: [list fixtures].</p>
        <p>1.3 Only [Specify product categories] sold by e-commerce sellers may be displayed on the Shelf Space.</p>
        <p>1.4 The Retailer shall maintain the Shelf Space in clean, tidy, and functional condition.</p>

        <h4>2. Display Standards</h4>
        <p>2.1 The Retailer shall clean, dust, and upkeep the Shelf Space [daily / weekly / monthly]. Products of e-commerce sellers shall not be handled or moved without prior permission.</p>
        <p>2.2 The display positioning, lighting, and signage aesthetics used on the Shelf Space shall comply with the style guidelines set out in Schedule B.</p>
        <p>2.3 Products displayed shall meet all applicable laws and safety standards. The Retailer will reasonably cooperate to obtain any required licenses and permits.</p>

        <h4>3. Retailer Responsibilities</h4>
        <p>3.1 The Retailer shall take reasonable care to prevent damage to any products placed on the Shelf Space by the renting e-commerce businesses.</p>
        <p>3.2 The Retailer undertakes to process customer payments, facilitate billing and returns where applicable, and provide periodic sales reports to the seller and to the Marketplace.</p>
        <p>3.3 The Retailer grants renting e-commerce sellers rights to put up promotional signs and carry out joint marketing efforts within and in the immediate vicinity of the store premises. Specifics to be discussed separately between the Retailer and seller.</p>

        <h4>4. Rental Tenure</h4>
        <p>4.1 The minimum notice period for the removal of shelf from the marketplace shall be 30 days. Thereafter, either the Retailer or the e-commerce seller may terminate an active rental by serving 90 days written notice to the other party.</p>
        <p>4.2 Upon expiry or termination of a shelf rental arrangement, the e-commerce seller shall promptly remove all products and branding material from the Shelf Space at their own expense.</p>

        <h4>5. Online Listing</h4>
        <p>5.1 The Marketplace provides no guarantee as to foot traffic, views or sales that may result from products listed on rented shelf space. E-commerce sellers shall undertake their own due diligence.</p>
        <p>5.2 Modifications to the Shelf Space details on the Marketplace’s platform may take up to 48 business hours to reflect. Retailers shall intimate any material changes well in advance to the Marketplace.</p>

        <h4>6. Intellectual Property</h4>
        <p>6.1 Content uploaded regarding the Shelf Space listing shall belong to the uploading party. The Marketplace and uploading party grant each other licences to display, reproduce and otherwise use the uploaded content for purposes as required under this Agreement.</p>
        <p>6.2 Each party shall secure necessary permissions, rights or licences prior to uploading third party content.</p>

        <h4>7. Indemnities</h4>
        <p>7.1 The e-commerce seller shall fully indemnify and hold harmless the Retailer from any third party claims arising from the quality, safety or legality of products displayed on the rented Shelf Space.</p>
        <p>7.2 The Retailer shall fully indemnify and hold harmless the Marketplace from any claims arising from providing false, inaccurate or outdated information regarding its store or shelf spaces.</p>

        <h4>8. Platform Fee</h4>
        <p>8.1 The Marketplace shall charge a platform fee of <span className= "contract_highlight" style={{ fontWeight: 500 }} >15% plus applicable VAT</span> on the shelf rental fees payable by the retail stores listing shelf space via the platform.</p>

        <h4>9. Customer Information</h4>
<div className="customer_information_grid">
  <label>
    Company Name:
    <input type="text" placeholder="Company Name" />
  </label>
  <label>
    Billing Contact:
    <input type="text" placeholder="Billing Contact" />
  </label>
  <label>
    Business Contact:
    <input type="text" placeholder="Business Contact" />
  </label>
  <label>
    Phone:
    <input type="text" placeholder="Phone" />
  </label>
  <label>
    Address:
    <input type="text" placeholder="Address" />
  </label>
  <label>
  Email:
  <input 
    type="email" 
    placeholder="Email" 
    defaultValue={localStorage.getItem('user_email') || ''} 
  />
</label>

</div>


        <div className="agreement_sign">
  <h4>9.1 Accepted and Agreed by</h4>
</div>

<div className="billing_agreement">
  <div className="agreement_sign_customer">
    <p>Customer</p>
    <p>By: {Customer_name}</p>
    <p>Title: Store Owner</p>
    <p>Date: {day}/{monthIndex}/{year}</p>
  </div>

  <div className="agreement_sign_company">
    <p className='logo_company_name_signed'> <img className='company_logo_signed_contract' src="/contract_logo.svg" alt ="" />  <div className="company_name_signed">THIRDRETAIL LIMITED</div> </p>
    <p>By: Mohammed Athar Ali</p>
    <p>Title: Co-Founder & CEO</p>
    <p>Date: {day}/{monthIndex}/{year}</p>
  </div>
</div>


<div className="contract_accept_reject_section">


  <div
        className={`accept_contract ${acceptcontract ? "accepted" : ""}`}
        onClick={handleAccept}
      >
        {!acceptcontract && "Accept"}
        {acceptcontract && (
          <div className='accepted_text_img'>

            <div className='accepted_img' >   <img
              src="/CheckCircle.svg" 
              alt="Accepted"
              className="accepted_image"
            /></div>
            <div className="accepted_text">Accepted</div>
          </div>
        )}
      </div>


</div>

<ToastContainer />
      </div>


    </div>
  );
}

export default ContractDetails;
